import { Row } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { ApiClient } from '../../../../api';
import styles from './styles';
const LineWrapper = styled.div`
    ${styles}
`;

const combineData = (data: any) => {
    const combinedData: any = [];
    const combinedStatData: any = [];
    data?.forEach((item: any) => {
        combinedData.push({
            name: item?.game?.title,
            amountWon: Number(item?.totalLossAmount),
            amountLost: Number(item?.totalWinAmount),
        });
        combinedStatData.push({
            name: item?.game?.title,
            gameWon: Number(item?.totalLosses),
            gameLost: Number(item?.totalWins),
            gameDraw: Number(item?.totalDraws),
        });
    });
    return { combinedData, combinedStatData };
};

const BotStatsChart = () => {
    const { data: statsData } = useQuery(['botStats'], ApiClient.getBotStats);

    const { combinedData: data, combinedStatData } = useMemo(() => {
        return combineData(statsData);
    }, [statsData]);

    return (
        <div className="dashboard-card">
            <div className="dashboard-card-header">
                <div className="dashboard-card-header-title">BOT INSIGHTS</div>
            </div>
            <Row style={{ width: '100%', gap: 15 }}>
                <LineWrapper>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="amountWon" fill="#8884d8" name="Amount Won" />
                            <Bar dataKey="amountLost" fill="#82ca9d" name="Amount Lost" />
                        </BarChart>
                    </ResponsiveContainer>
                </LineWrapper>
                <LineWrapper>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                            width={500}
                            height={300}
                            data={combinedStatData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="gameWon" fill="#8884d8" name="Game Won" />
                            <Bar dataKey="gameLost" fill="#82ca9d" name="Game Lost" />
                            <Bar dataKey="gameDraw" fill="#1E87F0" name="Draw games" />
                        </BarChart>
                    </ResponsiveContainer>
                </LineWrapper>
            </Row>
        </div>
    );
};

export default BotStatsChart;
