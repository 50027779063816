import defaultAxios from 'axios';
import * as Path from './paths';

const PROD_URL = 'https://games.weplay.africa';

// const DEV_URL = PROD_URL;
const DEV_URL = 'http://localhost:3000';

const __PROD__ = !process.env.NODE_ENV || process.env.NODE_ENV === 'production';

const baseUrl = __PROD__ ? PROD_URL : DEV_URL;

export const axios = defaultAxios.create({
    baseURL: baseUrl,
});

function getImageUrl(url?: string) {
    try {
        if (typeof url === 'string' && url?.startsWith('/files/')) {
            return baseUrl.concat(url);
        }
        return url;
    } catch (e: any) {
        console.log(e?.message, url);
        return url;
    }
}

export const API = {
    get: axios.get,
    post: axios.post,
    patch: axios.patch,
    delete: axios.delete,
    put: axios.put,
    PATH: Path,
    baseUrl: baseUrl,
    CancelToken: defaultAxios.CancelToken,
    getImageUrl: getImageUrl,
};
