const prefix = 'insights';

export const get_withdrawal_stats = `${prefix}/withdrawal-stats`;
export const get_mpesa_transactions = (type: string) => `${prefix}/${type}`;

export const get_admin_user_stats = (startDate: string, endDate: string) =>
    `admin/stats?startDate=${startDate}&endDate=${endDate}&queryKey=UserInsights`;
export const get_admin_payment_stats = (startDate: string, endDate: string) =>
    `admin/stats?startDate=${startDate}&endDate=${endDate}&queryKey=PaymentInsights`;
export const get_admin_game_stats = (startDate: string, endDate: string) =>
    `admin/stats?startDate=${startDate}&endDate=${endDate}&queryKey=GameInsights`;
export const get_bot_stats = `admin/bot_stats`;

// Not used
export const get_deposit_stats = `${prefix}/platform-revenues`;

export const get_insights = (startDate: string, endDate: string) =>
    `${prefix}/game-stats?startDate=${startDate}&endDate=${endDate}`;

export const get_daily_signups = (startDate: string, endDate: string) =>
    `${prefix}/daily-signups?startDate=${startDate}&endDate=${endDate}`;

export const get_daily_gameplays = (startDate: string, endDate: string) =>
    `${prefix}/daily-gameplays?startDate=${startDate}&endDate=${endDate}`;
