import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import { DrawerLayout } from '../../Layout';
import style from './styles';
import { convertNumberToCommaString, hex2rgba } from '../../utils/helper';
import { ROUTES } from '../../router';
import { BotStatsChart, FirstLineChart, GameLineChart, PaymentLineChart } from './components';
import AdminRoleWrapper from '../../utils/RoleWrapper';

const DashboardWrapper = styled.div`
    ${style}
`;

interface Props {
    withdrawalsData: any;
}

const colors = ['#1E87F0', '#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#00ff00', '#00ffff'];

const DashBoard: FunctionComponent<Props> = ({ withdrawalsData }: Props) => {
    const navigate = useNavigate();

    const handleClick = (link: string) => {
        if (link === 'Payments') {
            navigate(ROUTES.PAYMENTS);
        } else if (link === 'Users') {
            navigate(ROUTES.USERS);
        } else if (link === 'Games') {
            navigate(ROUTES.GAMES);
        }
    };

    return (
        <DrawerLayout>
            <DashboardWrapper>
                <AdminRoleWrapper roleAllowed={'ADMIN'}>
                    <div className="dashboard-links">
                        {['Payments', 'Users', 'Games'].map((link, index) => (
                            <div
                                key={link}
                                className="dashboard-link"
                                style={{
                                    backgroundColor: hex2rgba(colors[index], 0.1),
                                    color: colors[index],
                                    border: `1px solid ${colors[index]}`,
                                }}
                                onClick={handleClick.bind(null, link)}
                            >
                                {link}
                            </div>
                        ))}
                    </div>
                </AdminRoleWrapper>
                <Row style={{ gap: 15, marginTop: 15, marginBottom: 15 }}>
                    <div className="dashboard-card-content-item">
                        <div className="dashboard-card-content-item-label">Current Withdrawal Balance</div>
                        <div className="dashboard-card-content-item-value primary-text">
                            <span className="currency">KES</span>{' '}
                            {convertNumberToCommaString(withdrawalsData?.current_withdrawal_balance ?? 0)}
                        </div>
                    </div>
                    <div className="dashboard-card-content-item">
                        <div className="dashboard-card-content-item-label">Current Working Account Balance</div>
                        <div className="dashboard-card-content-item-value primary-text">
                            <span className="currency">KES</span>{' '}
                            {convertNumberToCommaString(withdrawalsData?.current_working_account_balance ?? 0)}
                        </div>
                    </div>
                    <div className="dashboard-card-content-item">
                        <div className="dashboard-card-content-item-label">Current Deposits Account Balance</div>
                        <div className="dashboard-card-content-item-value primary-text">
                            <span className="currency">KES</span>{' '}
                            {convertNumberToCommaString(withdrawalsData?.current_deposits_account_balance ?? 0)}
                        </div>
                    </div>
                </Row>
                <Row style={{ width: '100%', gap: 15 }}>
                    <FirstLineChart />
                    <PaymentLineChart />
                </Row>
                <Row style={{ width: '100%', gap: 15, marginTop: 15 }}>
                    <GameLineChart />
                </Row>
                <AdminRoleWrapper roleAllowed={'ADMIN'}>
                    <Row style={{ width: '100%', gap: 15, marginTop: 15 }}>
                        <BotStatsChart />
                    </Row>
                </AdminRoleWrapper>
            </DashboardWrapper>
        </DrawerLayout>
    );
};

export default DashBoard;
