import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';

const AdminRoleWrapper = ({ children, roleAllowed }) => {
    const user: any = useSelector<any>((state: RootState) => state.auth.data);

    const role = user?.email === 'marketing@weplayarcade.com' ? 'MARKETING' : 'ADMIN';
    if (role !== roleAllowed) {
        return null;
    }
    return children;
};

export default AdminRoleWrapper;
