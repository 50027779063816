import { QueryFunctionContext } from 'react-query';
import { API } from '../api';

export function getTransactions({ signal, pageParam = 1, queryKey }: QueryFunctionContext) {
    const type: string = queryKey && queryKey[1] && typeof queryKey[1] === 'string' ? queryKey[1] : 'deposits';
    const nameObject = queryKey[2] ? { search: queryKey[2] } : {};
    return API.get(API.PATH.get_mpesa_transactions(type), {
        params: {
            limit: 10,
            page: pageParam,
            ...nameObject,
        },
        signal: signal,
    }).then((res) => res.data);
}

export function getWithdrawalStats() {
    return API.get(API.PATH.get_withdrawal_stats).then((res) => res.data);
}

export function getAdminStats({ signal, queryKey }: QueryFunctionContext) {
    const startDate: string = queryKey && queryKey[1] && typeof queryKey[1] === 'string' ? queryKey[1] : '';
    const endDate: string = queryKey && queryKey[2] && typeof queryKey[2] === 'string' ? queryKey[2] : '';
    return API.get(API.PATH.get_admin_user_stats(startDate, endDate), {
        signal: signal,
    }).then((res) => res.data);
}

export function getAdminPaymentStats({ signal, queryKey }: QueryFunctionContext) {
    const startDate: string = queryKey && queryKey[1] && typeof queryKey[1] === 'string' ? queryKey[1] : '';
    const endDate: string = queryKey && queryKey[2] && typeof queryKey[2] === 'string' ? queryKey[2] : '';
    return API.get(API.PATH.get_admin_payment_stats(startDate, endDate), {
        signal: signal,
    }).then((res) => res.data);
}

export function getAdminGameStats({ signal, queryKey }: QueryFunctionContext) {
    const startDate: string = queryKey && queryKey[1] && typeof queryKey[1] === 'string' ? queryKey[1] : '';
    const endDate: string = queryKey && queryKey[2] && typeof queryKey[2] === 'string' ? queryKey[2] : '';
    return API.get(API.PATH.get_admin_game_stats(startDate, endDate), {
        signal: signal,
    }).then((res) => res.data);
}

export function getBotStats({ signal }: QueryFunctionContext) {
    return API.get(API.PATH.get_bot_stats, { signal }).then((res) => res.data);
}

// Not used
export function getDepositStats() {
    return API.get(API.PATH.get_deposit_stats).then((res) => res.data);
}

export function getInsights({ signal, queryKey }: QueryFunctionContext) {
    const startDate: string = queryKey && queryKey[1] && typeof queryKey[1] === 'string' ? queryKey[1] : '';
    const endDate: string = queryKey && queryKey[2] && typeof queryKey[2] === 'string' ? queryKey[2] : '';
    return API.get(API.PATH.get_insights(startDate, endDate), {
        signal: signal,
    }).then((res) => res.data);
}

export function getDailySignups({ signal, queryKey }: QueryFunctionContext) {
    const startDate: string = queryKey && queryKey[1] && typeof queryKey[1] === 'string' ? queryKey[1] : '';
    const endDate: string = queryKey && queryKey[2] && typeof queryKey[2] === 'string' ? queryKey[2] : '';
    return API.get(API.PATH.get_daily_signups(startDate, endDate), {
        signal: signal,
    }).then((res) => res.data);
}

export function getDailyGamePlays({ signal, queryKey }: QueryFunctionContext) {
    const startDate: string = queryKey && queryKey[1] && typeof queryKey[1] === 'string' ? queryKey[1] : '';
    const endDate: string = queryKey && queryKey[2] && typeof queryKey[2] === 'string' ? queryKey[2] : '';
    return API.get(API.PATH.get_daily_gameplays(startDate, endDate), {
        signal: signal,
    }).then((res) => res.data);
}
