import React, { FC, useState } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { SideBar } from './sidebar';
import { Header } from './header';
import { paginationStyles } from '../components/common';
import AdminRoleWrapper from '../utils/RoleWrapper';

const LayoutWrapper = styled(Layout)`
    min-height: 100vh;
    .ant-layout-header {
        background: white;
        padding: 0 24px;

        .anticon-menu {
            font-size: 18px;
        }
    }
    .ant-layout-content {
        padding: 20px;

        ${paginationStyles}
    }
`;

const DrawerLayout: FC<any> = ({ children }: any) => {
    const [collapsed, setCollapsed] = useState(false);

    const toggle = () => setCollapsed((s) => !s);

    return (
        <LayoutWrapper>
            <AdminRoleWrapper roleAllowed={'ADMIN'}>
                <Layout.Sider theme={'light'} collapsed={collapsed}>
                    <SideBar onPress={toggle} />
                </Layout.Sider>
            </AdminRoleWrapper>
            <Layout>
                <Layout.Header>
                    <Header collapsed={collapsed} toggle={toggle} />
                </Layout.Header>
                <Layout.Content>{children}</Layout.Content>
            </Layout>
        </LayoutWrapper>
    );
};

export default DrawerLayout;
