import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = () => css`
    .dashboard-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
        .dashboard-link {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.6rem 1.5rem;
            min-width: 150px;
            border-radius: 5px;
            font-weight: 500;
            text-decoration: none;
            transition: 0.2s ease-in-out;
            cursor: pointer;
            &:hover {
                transform: scale(1.05);
            }
        }
    }
    .dashboard-card-content-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        background-color: ${(props) => props.theme[COLORS.WHITE]};
        padding: 1rem;
        flex: 1;
        border-radius: 5px;
        .dashboard-card-content-item-value {
            font-size: 2.2rem;
            font-weight: 900;
            color: ${(props) => props.theme[COLORS.SUCCESS]};
            display: flex;
            align-items: flex-end;
            width: 100%;
            line-height: 1;
            margin-top: 0.5rem;
            justify-content: space-between;
            .currency {
                font-size: 1rem;
                line-height: 1.5;
                font-weight: 600;
                color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
            }
        }
        .dashboard-card-content-item-label {
            font-size: 0.8rem;
            font-weight: 500;
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .bordered {
            border: 1px solid ${(props) => props.theme[COLORS.BORDER]};
        }
        .primary-text {
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .primary {
            color: ${(props) => props.theme[COLORS.PRIMARY]};
        }
    }
    .dashboard-card {
        background-color: ${(props) => props.theme[COLORS.WHITE]};
        padding: 1rem;
        border-radius: 5px;
        width: 50%;
        min-width: 300px;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        .dashboard-card-header {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 1rem;
            .dashboard-card-header-title {
                font-size: 1.5rem;
                font-weight: 600;
                color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
            }
            .dashboard-card-header-date {
                .ant-picker-range {
                    border-radius: 5px;
                }
            }
        }
        .bordered {
            border: 1px solid ${(props) => props.theme[COLORS.BORDER]};
        }
        .primary-text {
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .primary {
            color: ${(props) => props.theme[COLORS.PRIMARY]};
        }
    }
`;

export default style();
