import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { COMPONENTS, ERROR_COMPONENTS } from './components';
import { RootState } from '../store/reducers';

import { AuthenticatedRoutes, UnAuthenticatedRoutes } from './routes';
import { ROUTES } from './url';
import { InMemoryJWTManager } from '../api';

interface UserRoleType {
    [key: string]: string[] | string;
}

export const UserRole: UserRoleType = {
    MARKETING: [ROUTES.DASHBOARD],
    ADMIN: 'ALL',
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const auth: any = useSelector<any>((state: RootState) => state.auth.authenticated);
    const user: any = useSelector<any>((state: RootState) => state.auth.data);

    const role = user?.email === 'marketing@weplayarcade.com' ? 'MARKETING' : 'ADMIN';

    const location = useLocation();

    if (role === 'MARKETING' && !UserRole.MARKETING.includes(location.pathname)) {
        return <Navigate to={ROUTES.DASHBOARD} />;
    }

    if (!InMemoryJWTManager.hasAccessToken()) {
        return <Navigate to={ROUTES.SIGN_IN} state={{ from: location }} replace />;
    }
    if (!auth) {
        return <Navigate to={ROUTES.SIGN_IN} state={{ from: location }} replace />;
    }

    return children;
};

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" element={COMPONENTS.HOME} />
            {UnAuthenticatedRoutes.map((route) => (
                <Route path={ROUTES[route]} element={COMPONENTS[route]} key={route} />
            ))}
            {AuthenticatedRoutes.map((route) => {
                return (
                    <Route path={ROUTES[route]} element={<RequireAuth>{COMPONENTS[route]}</RequireAuth>} key={route} />
                );
            })}
            <Route path="/admin" element={<Navigate to={ROUTES['DASHBOARD']} />} />
            <Route path="*" element={ERROR_COMPONENTS.ERROR_404} />
        </Routes>
    );
};

export default AppRouter;
