import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { DatePicker } from 'antd';
import { LineChart, XAxis, YAxis, Line, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import styled from 'styled-components';
import { ApiClient } from '../../../../api';
import styles from './styles';
const LineWrapper = styled.div`
    ${styles}
`;

const { RangePicker } = DatePicker;

const combineData = (d) => {
    const platformChargesData = d?.total_platform_charges;
    const withdrawalData = d?.total_withdrawal;
    const withdrawalChargesData = d?.total_withdrawal_charges;
    const depositsData = d?.total_deposit;
    const data: any[] = [];
    let maxVal = 0;
    for (let i = 0; i < platformChargesData?.length; i++) {
        const maxOfData = Math.max(
            platformChargesData[i].withdrawal,
            withdrawalData[i].withdrawal,
            withdrawalChargesData[i].withdrawal,
            depositsData[i].deposits,
        );
        if (maxOfData > maxVal) {
            maxVal = maxOfData;
        }
        data.push({
            date: platformChargesData[i].day,
            pc: platformChargesData[i].withdrawal,
            w: withdrawalData[i].withdrawal,
            wc: withdrawalChargesData[i].withdrawal,
            d: depositsData[i].deposits,
        });
    }
    return { data, maxVal };
};

const PaymentLineChart = () => {
    const [dateRange, setDateRange] = React.useState<[Moment, Moment]>([moment().subtract(1, 'month'), moment()]);
    const { data: statsData } = useQuery(
        ['paymentStats', dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')],
        ApiClient.getAdminPaymentStats,
    );
    const [opacity, setOpacity] = React.useState<any>({
        pc: 1,
        w: 1,
        wc: 1,
        d: 1,
    });
    const handleMouseEnter = (o) => {
        const { dataKey } = o;

        setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
    };
    const handleMouseLeave = (o) => {
        const { dataKey } = o;

        setOpacity((op) => ({ ...op, [dataKey]: 1 }));
    };

    const { data: d, maxVal } = useMemo(() => combineData(statsData), [statsData]);

    return (
        <div className="dashboard-card">
            <div className="dashboard-card-header">
                <div className="dashboard-card-header-title">PAYMENT INSIGHTS</div>
                <div className="dashboard-card-header-date">
                    <RangePicker
                        value={dateRange}
                        onChange={(values) => {
                            if (values && values[0] && values[1]) {
                                setDateRange([values[0], values[1]]);
                            }
                        }}
                    />
                </div>
            </div>
            <LineWrapper>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={d} margin={{ top: 10 }}>
                        <Legend verticalAlign="top" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                        <YAxis tickCount={5} domain={[0, Math.ceil(maxVal * 1.1)]} />
                        <XAxis
                            dataKey="date"
                            minTickGap={20}
                            tickFormatter={(value) => moment(value).format('DD MMM')}
                        />
                        <Tooltip labelFormatter={(label) => moment(label).format('DD MMM YYYY')} />
                        <Line
                            name="Platform Charges"
                            dot={false}
                            type="monotone"
                            opacity={opacity.iu}
                            dataKey="pc"
                            stroke="#07004d"
                        />
                        <Line
                            name="Withdrawals"
                            dot={false}
                            type="monotone"
                            opacity={opacity.pb}
                            dataKey="w"
                            stroke="#ff7300"
                        />
                        <Line
                            name="Withdrawal Charges"
                            dot={false}
                            type="monotone"
                            opacity={opacity.pb}
                            dataKey="wc"
                            stroke="#d5c67a"
                        />
                        <Line
                            name="Deposits"
                            dot={false}
                            type="monotone"
                            opacity={opacity.ib}
                            dataKey="d"
                            stroke="#1E87F0"
                        />
                    </LineChart>
                </ResponsiveContainer>
            </LineWrapper>
        </div>
    );
};

export default PaymentLineChart;
