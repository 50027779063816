import React from 'react';
import { ApiClient } from '../../api';
import DashBoard from '../../components/dashboard';
import { useQuery } from 'react-query';

const DashBoardContainer = () => {
    const { data: withdrawalsData } = useQuery('withdrawals', ApiClient.getWithdrawalStats);

    return <DashBoard withdrawalsData={withdrawalsData} />;
};

export default DashBoardContainer;
