import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { DatePicker } from 'antd';
import { LineChart, XAxis, YAxis, Line, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import styled from 'styled-components';
import { ApiClient } from '../../../../api';
import styles from './styles';
const LineWrapper = styled.div`
    ${styles}
`;

const { RangePicker } = DatePicker;

const combineData = (d) => {
    const gameData = d?.game_insights;
    const data: any = {};
    let maxVal = 0;
    const gameTitles: any[] = [];
    const gameKeys: any[] = [];
    for (let i = 0; i < gameData?.length; i++) {
        const val = gameData?.[i]?.total_games_played;
        if (val > maxVal) {
            maxVal = val;
        }
        const key = gameData?.[i]?.game_title?.toLowerCase()?.replace(/ /g, '_');
        if (!gameTitles.includes(gameData?.[i]?.game_title)) {
            gameTitles.push(gameData?.[i]?.game_title);
            gameKeys.push(key);
        }
        data[gameData[i].day] = {
            ...(data[gameData?.[i]?.day] || {}),
            date: gameData[i].day,
            [key]: val,
        };
    }
    return { data: Object.values(data), maxVal, gameTitles, gameKeys };
};

const GameLineChart = () => {
    const [dateRange, setDateRange] = React.useState<[Moment, Moment]>([moment().subtract(1, 'month'), moment()]);
    const { data: statsData } = useQuery(
        ['gameStats', dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')],
        ApiClient.getAdminGameStats,
    );

    const { data: d, maxVal, gameKeys, gameTitles } = useMemo(() => combineData(statsData), [statsData]);

    return (
        <div className="dashboard-card">
            <div className="dashboard-card-header">
                <div className="dashboard-card-header-title">GAMES PLAYED</div>
                <div className="dashboard-card-header-date">
                    <RangePicker
                        value={dateRange}
                        onChange={(values) => {
                            if (values && values[0] && values[1]) {
                                setDateRange([values[0], values[1]]);
                            }
                        }}
                    />
                </div>
            </div>
            <LineWrapper>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={d} margin={{ top: 10 }}>
                        <Legend verticalAlign="top" />
                        <YAxis tickCount={5} domain={[0, Math.ceil(maxVal * 1.1)]} />
                        <XAxis
                            dataKey="date"
                            minTickGap={20}
                            tickFormatter={(value) => moment(value).format('DD MMM')}
                        />
                        <Tooltip labelFormatter={(label) => moment(label).format('DD MMM YYYY')} />
                        {gameTitles.map((title, index) => {
                            return (
                                <Line
                                    name={title}
                                    key={index}
                                    dot={false}
                                    type="monotone"
                                    dataKey={gameKeys[index]}
                                    stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                                />
                            );
                        })}
                    </LineChart>
                </ResponsiveContainer>
            </LineWrapper>
        </div>
    );
};

export default GameLineChart;
