import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { DatePicker } from 'antd';
import { LineChart, XAxis, YAxis, Line, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import styled from 'styled-components';
import { ApiClient } from '../../../../api';
import styles from './styles';
const LineWrapper = styled.div`
    ${styles}
`;

const { RangePicker } = DatePicker;

const combineData = (d) => {
    const churnData = d?.rec_churn;
    const playingBase = d?.active_customer_base_playing;
    const depositBase = d?.active_customer_last_deposit;
    const inactiveBase = d?.gross_add_inactive;
    const activeBase = d?.gross_active;
    const reactivatedBase = d?.winback_30_90;
    const reconnections = d?.reconnections;
    const data: any[] = [];
    let maxVal = 0;
    for (let i = 0; i < churnData?.length; i++) {
        const maxOfData = Math.max(
            churnData[i].inactive_users_count,
            playingBase[i].active_playing_players_count,
            depositBase[i].active_transaction_players_count,
            inactiveBase[i].gross_add_inactive,
            activeBase[i].gross_add_active,
            reactivatedBase[i].reactivated_users_count,
            reconnections[i].reconnections,
        );
        if (maxOfData > maxVal) {
            maxVal = maxOfData;
        }
        data.push({
            date: churnData[i].day,
            iu: churnData[i].inactive_users_count,
            pb: playingBase[i].active_playing_players_count,
            db: depositBase[i].active_transaction_players_count,
            ib: inactiveBase[i].gross_add_inactive,
            ab: activeBase[i].gross_add_active,
            rb: reactivatedBase[i].reactivated_users_count,
            rc: reconnections[i].reconnections,
        });
    }
    return { data, maxVal };
};

const FirstLineChart = () => {
    const [dateRange, setDateRange] = React.useState<[Moment, Moment]>([moment().subtract(1, 'month'), moment()]);
    const { data: statsData } = useQuery(
        ['adminStats', dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')],
        ApiClient.getAdminStats,
    );
    const [opacity, setOpacity] = React.useState<any>({
        iu: 1,
        pb: 1,
        db: 1,
        ib: 1,
        ab: 1,
        rb: 1,
        rc: 1,
    });
    const handleMouseEnter = (o) => {
        const { dataKey } = o;

        setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
    };
    const handleMouseLeave = (o) => {
        const { dataKey } = o;

        setOpacity((op) => ({ ...op, [dataKey]: 1 }));
    };

    const { data: d, maxVal } = useMemo(() => combineData(statsData), [statsData]);

    return (
        <div className="dashboard-card">
            <div className="dashboard-card-header">
                <div className="dashboard-card-header-title">USER INSIGHTS</div>
                <div className="dashboard-card-header-date">
                    <RangePicker
                        value={dateRange}
                        onChange={(values) => {
                            if (values && values[0] && values[1]) {
                                setDateRange([values[0], values[1]]);
                            }
                        }}
                    />
                </div>
            </div>
            <LineWrapper>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={d}>
                        <Legend verticalAlign="top" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                        <YAxis tickCount={5} domain={[0, Math.ceil(maxVal * 1.1)]} />
                        <XAxis
                            dataKey="date"
                            minTickGap={20}
                            tickFormatter={(value) => moment(value).format('DD MMM')}
                        />
                        <Tooltip labelFormatter={(label) => moment(label).format('DD MMM YYYY')} />
                        <Line
                            name="No activity in last 30 days"
                            dot={false}
                            type="monotone"
                            opacity={opacity.iu}
                            dataKey="iu"
                            stroke="#ff7300"
                        />
                        <Line
                            name="Played in last 30 days"
                            dot={false}
                            type="monotone"
                            opacity={opacity.pb}
                            dataKey="pb"
                            stroke="#07004d"
                        />
                        <Line
                            name="Deposited in last 30 days"
                            dot={false}
                            type="monotone"
                            opacity={opacity.pb}
                            dataKey="db"
                            stroke="#d5c67a"
                        />
                        <Line
                            name="No activity after registration"
                            dot={false}
                            type="monotone"
                            opacity={opacity.ib}
                            dataKey="ib"
                            stroke="#1E87F0"
                        />
                        <Line
                            name="Active after registration"
                            dot={false}
                            type="monotone"
                            opacity={opacity.ab}
                            dataKey="ab"
                            stroke="#42e2b8"
                        />
                        <Line
                            name="Reactivated after no activity in 30 days, last activity within 90 days"
                            dot={false}
                            type="monotone"
                            opacity={opacity.rb}
                            dataKey="rb"
                            stroke="#110b11"
                        />
                        <Line
                            name="Back after no activity in last 90 days"
                            dot={false}
                            type="monotone"
                            opacity={opacity.rc}
                            dataKey="rc"
                            stroke="#F08080"
                        />
                    </LineChart>
                </ResponsiveContainer>
            </LineWrapper>
        </div>
    );
};

export default FirstLineChart;
